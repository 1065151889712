import React from 'react';
import {Bar, CartesianGrid, ComposedChart, LabelList, Tooltip, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import PropTypes from 'prop-types';

const OrdersGraph = props =>
    <ResponsiveContainer height='100%' minHeight={250} width='100%'>
        <ComposedChart data={props.data}>
            <CartesianGrid stroke='#eeeeee'/>
            <XAxis dataKey='date' interval='preserveStartEnd' tick={{fontSize: 12}}/>
            <YAxis allowDecimals={false} label={{value: 'Tickets Sold', angle: -90}} tick={{fontSize: 12}}/>
            <Bar dataKey='quantity' fill='#ffb22b' maxBarSize={50}>
                <LabelList dataKey='quantity' position='insideTop' style={{fontSize: 12}}/>
            </Bar>
            <Tooltip/>
        </ComposedChart>
    </ResponsiveContainer>;

OrdersGraph.propTypes = {
    data: PropTypes.array.isRequired
};

export default OrdersGraph;